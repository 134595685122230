import { FC, ReactNode } from 'react'

import { Text } from '@radix-ui/themes'
import { Link } from '@tanstack/react-router'
import cx from 'clsx'
import { useSidebar } from 'hooks/useSidebar'

import classes from './MenuItem.module.scss'

interface MenuItemProps {
  href?: string
  icon?: ReactNode
  children?: ReactNode
  onClick?: () => void
  caretIcon?: ReactNode
}

export const MenuItem: FC<MenuItemProps> = ({ href, icon, children, onClick, caretIcon }) => {
  const collapsed = useSidebar((state) => state.collapsed)

  return (
    <li className={cx(classes.item, { [classes.collapsed]: collapsed })} color="red" onClick={onClick}>
      <Link className={classes.link} to={href}>
        {icon && <span className={cx(classes.icon, { [classes.collapsed]: collapsed })}>{icon}</span>}
        <Text className={cx(classes.text, { [classes.collapsed]: collapsed })}>{children}</Text>
        {caretIcon}
      </Link>
    </li>
  )
}
