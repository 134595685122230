import { axios } from 'config/axios'
import { GetOneData, GetOneResponse } from 'interfaces/api.interfaces'

import { profileEndpoints } from './endpoints'
import { Profile } from './interfaces'

const getProfile = async (): GetOneResponse<Profile> => {
  const { data } = await axios.request<GetOneData<Profile>>({
    method: 'GET',
    url: profileEndpoints.data(),
  })
  return data
}

export const profileApi = {
  getProfile,
}
