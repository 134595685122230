import { FC } from 'react'

import { Button, DropdownMenu, Text } from '@radix-ui/themes'
import { useProfile } from 'admin/profile/queries/useProfile'
import ArrowBottom from 'assets/images/arrow-bottom.svg?react'
import avatar from 'assets/images/Avatar.png'
import { useTranslate } from 'config/i18n'
import { useLogout } from 'hooks/useLogout'
import { capitalize } from 'lodash'

import classes from './User.module.scss'

export const User: FC = () => {
  const translate = useTranslate()

  const { profile } = useProfile()
  const { logout } = useLogout()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={classes.userMenu}>
        <Button className={classes.button} variant="soft">
          <img alt="Avatar" className={classes.avatar} src={avatar} />
          <div className={classes.info}>
            <Text className={classes.personalData}>{profile?.name}</Text>
            <Text className={classes.profession}>
              {capitalize(profile?.roles?.map((item) => item.name).join(', '))}
            </Text>
          </div>
          <ArrowBottom className={classes.caretIcon} />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item className={classes.item}>{translate('edit')}</DropdownMenu.Item>
        <DropdownMenu.Item className={classes.item} onClick={logout}>
          {translate('exit')}
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
