import { FC, PropsWithChildren } from 'react'

import { useProfile } from 'admin/profile/queries/useProfile'
import { Header } from 'components/template/Header'
import { Sidebar } from 'components/template/Sidebar'
import { Error } from 'ui/Error'
import { Loader, LoaderTypes } from 'ui/Loader'

import classes from './MainLayout.module.scss'

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, error } = useProfile()

  if (isLoading) {
    return <Loader type={LoaderTypes.SpinnerCenter} />
  }

  if (error) {
    return (
      <div className={classes.error}>
        <Error error={error} />
      </div>
    )
  }

  return (
    <section className={classes.wrap}>
      <Sidebar />
      <main className={classes.main}>
        <Header />

        <div className={classes.page}>{children}</div>
      </main>
    </section>
  )
}
