import { FC, ReactNode } from 'react'

import { Text } from '@radix-ui/themes'
import { Link } from '@tanstack/react-router'
import cx from 'clsx'
import { useSidebar } from 'hooks/useSidebar'

import classes from './MenuItemNavigate.module.scss'

interface MenuItemProps {
  href?: string
  icon?: ReactNode
  children?: ReactNode
  onClickNavigate?: (attributeTypeId: string) => void
  caretIcon?: ReactNode
  itemId: number
  isSelected?: boolean
}

export const MenuItemNavigate: FC<MenuItemProps> = ({
  href,
  icon,
  children,
  caretIcon,
  onClickNavigate,
  itemId,
  isSelected = false,
}) => {
  const collapsed = useSidebar((state) => state.collapsed)

  const handleClick = () => {
    if (onClickNavigate) {
      onClickNavigate(String(itemId))
    }
  }

  return (
    <li
      className={cx(classes.item, {
        [classes.collapsed]: collapsed,
        [classes.selected]: isSelected,
      })}
      color="red"
      onClick={handleClick}
    >
      <Link className={classes.link} to={href}>
        {icon && <span className={cx(classes.icon, { [classes.collapsed]: collapsed })}>{icon}</span>}
        <Text className={cx(classes.text, { [classes.collapsed]: collapsed })}>{children}</Text>
        {caretIcon}
      </Link>
    </li>
  )
}
