import { FC } from 'react'

import { useNavigate } from '@tanstack/react-router'
import LogoText from 'assets/images/logo-text.svg?react'
import LogoWithoutText from 'assets/images/logo-without-text.svg?react'
import cx from 'clsx'
import { useSidebar } from 'hooks/useSidebar'

import classes from './Logo.module.scss'

export const Logo: FC = () => {
  const collapsed = useSidebar((state) => state.collapsed)
  const navigate = useNavigate()

  return (
    <div
      className={classes.logo}
      onClick={() => {
        navigate({
          to: '/',
        })
      }}
    >
      <LogoWithoutText className={cx('svg-img', classes.logoSvg)} />
      <LogoText className={cx(classes.logoText, { [classes.opacity]: collapsed })} />
    </div>
  )
}
