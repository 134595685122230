import { FC } from 'react'

import { ApiError } from 'packages/api'
import { getErrorMessage } from 'packages/react-query'

import classes from './error.module.scss'

interface ErrorProps {
  error?: ApiError | null
}

export const Error: FC<ErrorProps> = ({ error }) => <div className={classes.error}>{getErrorMessage(error)}</div>
