import { FC, memo, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import * as Form from '@radix-ui/react-form'
import { Text } from '@radix-ui/themes'
import cx from 'clsx'

import classes from './FormField.module.scss'

interface FormFieldProps {
  className?: string
  name: string
  label?: string
  error?: FieldError
  children: ReactNode
  required?: boolean
}

export const FormField: FC<FormFieldProps> = memo(({ className, name, label, error, children, required }) => (
  <Form.Field
    className={cx(className, { [classes.field]: typeof (children as any)?.props?.checked !== 'undefined' })}
    name={name}
  >
    {label && (
      <div className={classes.label}>
        <Form.Label>
          {label}
          {required ? ' *' : ''}
        </Form.Label>
      </div>
    )}

    {children}

    {error && (
      <Text className={classes.error} color="red">
        {error.message}
      </Text>
    )}
  </Form.Field>
))
