import { FC } from 'react'

import ArrowIcon from 'assets/images/arrow.svg?react'
import cx from 'clsx'
import { useSidebar } from 'hooks/useSidebar'

import { BottomMenu } from './BottomMenu'
import { Logo } from './Logo'
import classes from './Sidebar.module.scss'
import { SidebarMenu } from './SidebarMenu'

export const Sidebar: FC = () => {
  const { collapsed, toggle } = useSidebar()

  return (
    <>
      <div className={cx(classes.borderIcon, { [classes.collapsed]: collapsed })}>
        <ArrowIcon className={cx(classes.arrowIcon, { [classes.collapsed]: collapsed })} onClick={toggle} />
      </div>

      <aside className={cx(classes.sidebar, { [classes.collapsed]: collapsed })}>
        <Logo />

        <SidebarMenu />
        <BottomMenu />
      </aside>
    </>
  )
}
