import { ComponentProps, FC, forwardRef, ReactNode } from 'react'

import { Button as ButtonRadix } from '@radix-ui/themes'
import cx from 'clsx'
import { Loader, LoaderColors } from 'ui/Loader'

import classes from './Button.module.scss'

interface ButtonProps extends ComponentProps<typeof ButtonRadix> {
  isLoading?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
}

export const Button: FC<ButtonProps> = forwardRef(
  ({ isLoading, children, startIcon, endIcon, size = '2', className, ...props }, ref) => (
    <ButtonRadix
      className={cx(classes.button, classes[`size${size}`], { [classes.loading]: isLoading }, className)}
      size={size}
      {...props}
      ref={ref}
    >
      {isLoading && (
        <div className={classes.loader}>
          <Loader color={LoaderColors.White} />
        </div>
      )}

      <div className={classes.flex}>
        {startIcon && <span className={classes.icon}>{startIcon}</span>}
        {children}
        {endIcon && <span className={classes.icon}>{endIcon}</span>}
      </div>
    </ButtonRadix>
  ),
)
