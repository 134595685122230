import { useQuery } from '@tanstack/react-query'
import { profileApi } from 'admin/profile/api/methods'
import { useTranslate } from 'config/i18n'
import { useIsAuth } from 'hooks/useIsAuth'

export const useProfile = () => {
  const auth = useIsAuth((state) => state.auth)
  const translate = useTranslate()

  const { isPending, error, data } = useQuery({
    queryKey: ['userData'],
    queryFn: profileApi.getProfile,
    enabled: auth,
    meta: { error: { showToast: false, message: translate('apiErrors.profile') } },
  })

  return {
    isLoading: isPending,
    error,
    profile: data?.data,
  }
}
