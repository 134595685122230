import { FC, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import * as Form from '@radix-ui/react-form'
import { TextField } from '@radix-ui/themes'
import { AttributeTypeFormData } from 'admin/attributes/api/interface'
import { useCreateAttributeType } from 'admin/attributes/queries/useCreateAttributeType'
import { useUpdateAttributeType } from 'admin/attributes/queries/useUpdateAttributeType'
import { useTranslate } from 'config/i18n'
import { DialogButtons } from 'ui/Dialog/DialogButtons'
import { FormField } from 'ui/FormField'

import classes from './AttributeTypeForm.module.scss'

interface ValidationErrors {
  slug?: string
  name?: string
}

interface Props {
  isNew?: boolean
}

export const AttributeTypeForm: FC<Props> = ({ isNew }) => {
  const translate = useTranslate()

  const { saveAttributeType, isLoading: isLoadingSave } = useUpdateAttributeType()
  const { addAttributeType, errors, isLoading: isLoadingAdd } = useCreateAttributeType()

  const { control, handleSubmit, setError, clearErrors } = useFormContext<AttributeTypeFormData>()

  const onSubmit = (data: AttributeTypeFormData) => {
    clearErrors()
    if (isNew) {
      addAttributeType(data)
    } else {
      saveAttributeType(data)
    }
  }

  useEffect(() => {
    if (errors?.validation) {
      Object.entries(errors.validation).forEach(([key, message]) => {
        setError(key as keyof ValidationErrors, { message })
      })
    }
  }, [errors])

  return (
    <Form.Root className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <FormField error={error} label="Параметр" name="name" required>
            <TextField.Input size="3" {...field} />
          </FormField>
        )}
      />

      <Controller
        control={control}
        name="slug"
        render={({ field, fieldState: { error } }) => (
          <FormField error={error} label="slug" name="slug" required>
            <TextField.Input size="3" {...field} />
          </FormField>
        )}
      />

      <Controller
        control={control}
        name="order"
        render={({ field, fieldState: { error } }) => (
          <FormField error={error} label="order" name="order" required>
            <TextField.Input size="3" {...field} />
          </FormField>
        )}
      />

      <DialogButtons
        cancelText={translate('cancel')}
        isLoading={isLoadingSave || isLoadingAdd}
        okText={translate('save')}
        submit
      />
    </Form.Root>
  )
}
