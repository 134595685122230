import { AttributeTypeFormData, AttributeValueFormData } from 'admin/attributes/api/interface'
import { CitiesFormData } from 'admin/cities/api'
import { ProductFormData } from 'admin/products/api'
import { UsersFormData } from 'admin/users/api'
import {
  categoryMinError,
  integerNumberError,
  invalidEmailAddress,
  positiveNumberError,
  requiredError,
} from 'config/errors'
import { ImageData } from 'interfaces/common.interfaces'
import * as yup from 'yup'

const imageSchema: yup.ObjectSchema<ImageData> = yup.object().shape({
  file: yup.mixed(),
  id: yup.mixed<string | number>().required(),
  url: yup.string(),
})

export const productSchema: yup.ObjectSchema<ProductFormData> = yup.object().shape({
  name: yup.string().required(requiredError),
  price: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .positive(positiveNumberError)
    .required(requiredError)
    .integer(integerNumberError),
  discountPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .positive(positiveNumberError)
    .required(requiredError)
    .integer(integerNumberError),
  isActive: yup.boolean().required(),
  isFinished: yup.boolean().required(),
  description: yup.string().required(requiredError),
  categoryIds: yup.array().of(yup.number().positive().integer().required()).min(1, categoryMinError).required(),
  media: yup.object().shape({
    images: yup.array().of(imageSchema).required(),
  }),
  attributeValueIds: yup.object(),
  shopId: yup.number(),
})

export const attributeTypeSchema: yup.ObjectSchema<AttributeTypeFormData> = yup.object().shape({
  name: yup.string().required(requiredError),
  slug: yup.string().required(requiredError),
  order: yup.number().required(requiredError),
})

export const attributeValueSchema: yup.ObjectSchema<AttributeValueFormData> = yup.object().shape({
  slug: yup.string().required(requiredError),
  value: yup.string().required(requiredError),
  titleValue: yup.string().required(requiredError),
  order: yup.number().required(),
})

export const citySchema: yup.ObjectSchema<CitiesFormData> = yup.object().shape({
  name: yup.string().required(requiredError),
  slug: yup.string().required(requiredError),
  showInFooter: yup.boolean().required(),
})

export const userSchema: yup.ObjectSchema<UsersFormData> = yup.object().shape({
  name: yup.string().required(requiredError),
  email: yup.string().email(invalidEmailAddress).required(requiredError),
  password: yup.string(),
  phone: yup.string().required(requiredError),
  role: yup.object().shape({
    id: yup.number().required(),
    name: yup.string(),
  }),
})
