import { useEffect } from 'react'

import { useLocalStorageState, useSessionStorageState } from 'ahooks'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { storageAuthName } from 'constants/storage'
import { useIsAuth as getIsAuth } from 'hooks/useIsAuth'

export const useAuthenticated = () => {
  const [authLS, setAuthLS] = useLocalStorageState<{ value: boolean } | null>(storageAuthName, {
    defaultValue: { value: false },
  })
  const [authSession, setAuthSession] = useSessionStorageState<{ value: boolean } | null>(storageAuthName, {
    defaultValue: { value: false },
  })
  const authenticated = authLS?.value || authSession?.value
  if (authenticated) {
    setAxiosAuthorizationToken(storage.getToken())
    getIsAuth.getState().setAuth(true)
  }

  useEffect(() => {
    storage.onSetToken = (remember?: boolean) => {
      const setAuth = remember ? setAuthLS : setAuthSession
      const clearStorage = remember ? setAuthSession : setAuthLS
      setAuth({ value: true })
      clearStorage({ value: false })
      getIsAuth.getState().setAuth(true)
    }
    storage.onClearToken = () => {
      setAuthLS({ value: false })
      setAuthSession({ value: false })
      getIsAuth.getState().setAuth(false)
    }
  }, [])

  return { authenticated }
}
