import { FC, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { AddAttributeTypeButton } from 'admin/attributes/components/AddAttributeTypeButton'
import CaretIcon from 'assets/images/caret-icon.svg?react'
import ParameterIcon from 'assets/images/parameter.svg?react'
import cx from 'clsx'
import { MenuItem } from 'components/template/Sidebar/MenuItem/MenuItem'
import { MenuItemNavigate } from 'components/template/Sidebar/MenuItemNavigate'
import { Animation, AnimationTypes } from 'ui/Animation'

import classes from './MenuItemList.module.scss'

interface Item {
  id: number
  name: string
}
interface Props {
  items: Item[]
  translate: string
  routesItem: string
}

export const MenuItemList: FC<Props> = ({ translate, items, routesItem }) => {
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null)
  const [showItemList, setShowItemList] = useState(false)

  const navigate = useNavigate()

  const onClickNavigate = (attributeTypeId: string) => {
    setSelectedItemId(Number(attributeTypeId))

    navigate({
      to: '/attribute-types/$attributeTypeId',
      params: { attributeTypeId },
    })
  }

  return (
    <div className={classes.menuItemList}>
      <div onClick={() => setShowItemList(!showItemList)}>
        <MenuItem
          caretIcon={<CaretIcon className={cx(classes.caretIcon, { [classes.rotateCaretIcon]: showItemList })} />}
          icon={<ParameterIcon />}
        >
          {translate}
        </MenuItem>
      </div>
      <Animation type={AnimationTypes.ScaleInMini}>
        {showItemList && (
          <ul className={classes.list}>
            {items &&
              items.map((item) => (
                <MenuItemNavigate
                  href={`${routesItem}/${item.id}`}
                  isSelected={selectedItemId === item.id}
                  itemId={item.id}
                  key={item.id}
                  onClickNavigate={onClickNavigate}
                >
                  {item.name}
                </MenuItemNavigate>
              ))}
            <li className={classes.button}>
              <AddAttributeTypeButton />
            </li>
          </ul>
        )}
      </Animation>
    </div>
  )
}
