import { FC } from 'react'

import { Theme } from '@radix-ui/themes'
import ExitIcon from 'assets/images/exit.svg?react'
import QuestionIcon from 'assets/images/question-mark-circled.svg?react'
import { MenuItem } from 'components/template/Sidebar/MenuItem'
import { useTranslate } from 'config/i18n'
import { useLogout } from 'hooks/useLogout'

import classes from './BottomMenu.module.scss'

export const BottomMenu: FC = () => {
  const translate = useTranslate()

  const { logout } = useLogout()
  return (
    <ul className={classes.bottomMenu}>
      <MenuItem icon={<QuestionIcon />}>{translate('menubar.help')}</MenuItem>
      <Theme accentColor="red">
        <MenuItem icon={<ExitIcon />} onClick={logout}>
          {translate('menubar.exit')}
        </MenuItem>
      </Theme>
    </ul>
  )
}
