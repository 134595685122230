import { FC, useEffect, useState } from 'react'

import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { Button } from 'ui/Button'

import classes from './Dialog.module.scss'

interface Props {
  cancelText: string
  okText: string
  isLoading?: boolean
  submit?: boolean
  danger?: boolean
  onOk?: () => void
  closeDialogAfterSuccess?: boolean
}

export const DialogButtons: FC<Props> = ({
  cancelText,
  okText,
  isLoading,
  submit,
  danger,
  onOk,
  closeDialogAfterSuccess = true,
}) => {
  const [isClicked, setIsClicked] = useState(false)

  const onOkInternal = () => {
    onOk?.()
    if (closeDialogAfterSuccess) {
      setTimeout(() => {
        setIsClicked(true)
      })
    }
  }

  useEffect(() => {
    if (closeDialogAfterSuccess && isClicked && !isLoading) {
      setIsClicked(false)
      dispatchEvent(new Event('closeDialog'))
    }
  }, [isClicked, isLoading, closeDialogAfterSuccess])

  return (
    <div className={classes.dialogButton}>
      <AlertDialog.Cancel asChild>
        <Button color="gray" size="3" variant="soft">
          {cancelText}
        </Button>
      </AlertDialog.Cancel>

      <Button
        color={danger ? 'red' : undefined}
        isLoading={isLoading}
        onClick={onOkInternal}
        size="3"
        type={submit ? 'submit' : undefined}
        variant="soft"
      >
        {okText}
      </Button>
    </div>
  )
}
