import { FC, ReactNode, useState } from 'react'

import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { useEventListener } from 'ahooks'
import CloseIcon from 'assets/images/icon-close.svg?react'
import { Button } from 'ui/Button'

import classes from './Dialog.module.scss'

interface PropsDialog {
  trigger?: ReactNode
  title?: string
  children?: ReactNode
}

export const Dialog: FC<PropsDialog> = ({ trigger, title, children }) => {
  const [open, setOpen] = useState(false)

  useEventListener('closeDialog' as any, () => setOpen(false))

  return (
    <div>
      <AlertDialog.Root onOpenChange={setOpen} open={open}>
        <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
        <AlertDialog.Portal container={document.body.querySelector('#portal') as HTMLElement}>
          <AlertDialog.Overlay className={classes.alertDialogOverlay} />
          <AlertDialog.Content className={classes.alertDialogContent}>
            {title && <AlertDialog.Title className={classes.alertDialogTitle}>{title}</AlertDialog.Title>}
            <AlertDialog.Description className={classes.alertDialogDescription}>{children}</AlertDialog.Description>

            <Button className={classes.iconClose} color="gray" onClick={() => setOpen(false)} variant="soft">
              <CloseIcon />
            </Button>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  )
}
