import { FC } from 'react'

import { useAttributeTypes } from 'admin/attributes/queries'
import CartIcon from 'assets/images/cart.svg?react'
import CityIcon from 'assets/images/city.svg?react'
import Users from 'assets/images/users.svg?react'
import { MenuItem } from 'components/template/Sidebar/MenuItem'
import { MenuItemList } from 'components/template/Sidebar/MenuItemList'
import { useTranslate } from 'config/i18n'

import classes from './MenuMarketAdmin.module.scss'

export const MenuMarketAdmin: FC = () => {
  const translate = useTranslate()
  const { attributeTypes } = useAttributeTypes()

  return (
    <ul className={classes.menu}>
      <MenuItem href="/products" icon={<CartIcon />}>
        {translate('menubar.products')}
      </MenuItem>
      <MenuItem href="/cities" icon={<CityIcon />}>
        {translate('cities.menuItem')}
      </MenuItem>
      <MenuItemList
        items={attributeTypes}
        routesItem="/attribute-types"
        translate={translate('attributeTypes.parameters')}
      />
      <MenuItem href="/users" icon={<Users />}>
        {translate('users.users')}
      </MenuItem>
    </ul>
  )
}
