import { FC } from 'react'

import Bell from 'assets/images/bell.svg?react'

import classes from './Header.module.scss'
import { User } from './User'

export const Header: FC = () => (
  <header className={classes.header}>
    <div className={classes.profile}>
      <div className={classes.notification}>
        <Bell className={classes.bell} />
      </div>
      <User />
    </div>
  </header>
)
