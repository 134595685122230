import { useNavigate } from '@tanstack/react-router'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'

export const useLogout = () => {
  const navigate = useNavigate()

  const logout = () => {
    setAxiosAuthorizationToken(null)
    storage.clearToken()
    navigate({ to: '/login' })
  }

  return { logout }
}
